<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-normal font-weight-medium">M360 Portal</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-2"/>

      <v-list-item two-line :to="{'name': 'Dashboard'}">
        <v-list-item-content :class="`${envSelected.color.name}--text`">
          <v-hover
              v-slot="{ hover }"
          >
            <v-list-item-title class="font-weight-regular" :active="true" :class="(hover)? 'white--text' : ''">
              <v-icon class="ml-3 mb-1 mr-1" :color="(hover) ? '': envSelected.color.code">mdi-view-dashboard</v-icon>
              Dashboard
            </v-list-item-title>
          </v-hover>
        </v-list-item-content>
      </v-list-item>

    <v-divider class="mb-2"/>

    <v-list
      nav
    >
      <template v-for="(item, i) in modules">

        <v-list-item
          link
          :id="item.id"
          :to="getPage(item)"
          :key="`leftMenu-${i}`"
          :class="(item.active) ? (`${envSelected.color.name} ${!isDark ? 'black' : 'white'}--text`) : ''"
          @click="handleMenuItem(item, $event)"
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon v-text="item.icon" />
          </v-list-item-icon>

          <v-list-item-content v-if="item.title || item.subtitle">
            <v-list-item-title v-text="item.title" />

            <v-list-item-subtitle v-text="item.subtitle" />
          </v-list-item-content>
        </v-list-item>
      </template>

    </v-list>

    <v-divider class="mb-2 d-md-none d-lg-none d-xl-none"/>
    <v-list
        nav
        class="d-md-none d-lg-none d-xl-none"
    >
      <v-list-item
          id="d-step-2"
          link
          href
          class="white--text"
          to="https://corsairm360.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all&status=open"
          target="_blank"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-ticket'" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="'TICKETS'" />
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-2"/>

      <v-list-item
          id="d-step-3"
          link
          href
          class="white--text"
          to="https://corsairm360.atlassian.net/servicedesk/customer/portals"
          target="_blank"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-face-agent'" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="'SUPPORT'" />
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-2"/>

      <v-list-item
          id="d-step-4"
          link
          href
          class="info--text"
          to="https://corsairm360.atlassian.net/servicedesk/customer/portal/4/article/1431437435?src=-1713164143"
          target="_blank"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-help-circle'" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="'Wiki'" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import Themeable from 'vuetify/lib/mixins/themeable'
  import {
    mapState,
  } from 'vuex'

  import menu from "../../../../config/leftMenu";
  import globalMixins from "../../../../mixins/globalMixins";

  export default {
    name: 'DashboardCoreDrawer',

    mixins: [globalMixins, Themeable],

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      }
    },

    data: () => ({
      modules: []
    }),

    computed: {
      ...mapState(['barColor']),
      drawer: {
        get() {
          return this.$store.state.drawer
        },
        set(val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
    },

    beforeDestroy() {
      this.modules = [];
    },

    async created() {
      await this.renderMenu();
    },

    watch: {
      '$vuetify.breakpoint.smAndDown'(val) {
        this.$emit('update:expandOnHover', !val)
      },

      $route(val){
        this.modules.forEach((oneModule) => {
          oneModule.active = oneModule.route === val.name;
        });
      }
    },

    methods: {

      async renderMenu(){
        this.modules = [];
        let leftMenu = await this.updateMenuEntries(JSON.parse(JSON.stringify(menu)));
        leftMenu.forEach((entry) => entry.active = false);
        this.modules = leftMenu;
      },

      mapItem(item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
