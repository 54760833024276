/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

let leftMenu = [
	{
		id: 'd-step-0',
		title: 'SAAS',
		route: 'saaSProjects',
		icon: 'mdi-cloud'
	},
	{
		id: 'd-step-1',
		title: 'Enterprise',
		route: 'enterpriseProjects',
		icon: 'mdi-server'
	},
];

module.exports = leftMenu;
